import packageColors from '../../helpers/package-colors.js'
import { mapActions, mapGetters } from 'vuex'
import ReportExport from '../../features/report-export/index.vue'
import reportMixin from '@/mixins/reportMixin'

export default {
  components: {
    ReportExport
  },
  mixins: [reportMixin],
  computed: {
    headers () {
      return [
        {
          text: 'Name',
          value: 'full_name'
        },
        {
          text: 'Package',
          value: 'package'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Progress',
          value: 'progress'
        },
        {
          text: 'Current material',
          value: 'material'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    colors () {
      return packageColors.colors
    },
    ...mapGetters({
      courseStudents: 'statistics/courseStudentsData',
      loading: 'statistics/loading'
    }),
    reportFn () {
      return this.createReportFn({
        url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}/users`,
        method: 'get',
        params: this.$route.params.packageId && {
          'filters[package_id]': this.$route.params.packageId
        }
      })
    }
  },

  created () {
    if (this.$route.params.packageId) {
      this.fetchStudents({ courseId: this.$route.params.courseId, packageId: this.$route.params.packageId })
    } else {
      this.fetchStudents({ courseId: this.$route.params.courseId })
    }
  },
  methods: {
    ...mapActions({
      fetchStudents: 'statistics/GET_STATISTICS_COURSE_STUDENTS'
    })
  }
}
